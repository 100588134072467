import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import GalleryContent from "./GalleryContent";

const GalleryPage = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div className="container pd-top-150 pd-bottom-100">
      <h2 className="text-center">Our Recent Projects</h2>
      <div className="">
        <div>
          {
            rpdata?.landings?.length > 0 ?
              rpdata?.landings?.map((item, index) => {
                return (
                  <div className="pt-20 pb-32">
                    <div className="text-center py-5" key={index}>
                      <h2 className="capitalize">{item.name}</h2>
                    </div>
                    <GalleryContent galleryImages={item.gallery} />
                  </div>
                )
              })
              :
              <div className="pb-32">
                <div className="text-center py-5">
                  <h2 className="text-center pt-[100px]">{rpdata?.labels?.general?.titleGallery}</h2>
                </div>
                <GalleryContent galleryImages={rpdata?.gallery?.length > 0 ? rpdata?.gallery : rpdata?.stock} />
              </div>
          }
        </div>
      </div>
    </div>

  );
};

export default GalleryPage;
