import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context';
import classes from './videopromocional.module.css';
const VideoPromocional = ({ linkVideo, image, title, text }) => {
    const dbData = useContext(GlobalDataContext);
    return (
        <div
            className={classes.bgCountentVideo}
            style={{
                backgroundImage: `url("${image ? image : dbData?.stock?.[0]}")`
            }}
        >
            <div className={classes.countentVideoInfo}>
                <div className={classes.info}>
                    <h2>{title}</h2>
                    {/* <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Link href='/contact'>
                            <a
                                className={`me-20 ${classes.btn} ${classes.btn_primary} ${classes.btn_hover__white}`}
                            >
                                Free Estimate
                            </a>
                        </Link>
                    </div> */}
                </div>
                <iframe
                    title="Promotional video"
                    className={`${classes.videoContent} z-10`}
                    id="ytplayer"
                    type="text/html"
                    src={`https://www.youtube.com/embed/${linkVideo}?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${linkVideo}`}
                />
            </div>
        </div>
    )
}
export default VideoPromocional